import Enum from './enum'

/**
 * 評価
 */
class Rank extends Enum {
    static PROSPECT = 1;
    static CONAN = 2;
    static FARM = 3;

    static values() {
        return {
            [this.PROSPECT]: '見込 (自分で追客）',
            [this.CONAN]: 'コナン (アポで追客)',
            [this.FARM]: '長期',
        }
    }
}

export default Rank;
