import Enum from './enum'

/**
 * 反響分類
 */
class ChannelCategory extends Enum {
    static CONTACT = 1; // お問合せ
    static DOWNLOAD = 2; // ダウンロード
    static PRESENT = 3; // プレゼント
    static ESTIMATE = 4; // お見積り依頼
    static DOCUMENT = 5; // 資料請求
    static FREE_CONSULT = 6; // 無料相談
    static SNS = 7; // SNS
    static INTRODUCTION = 8; // 紹介
    static COMPARE_SITE = 9; // 比較サイト
    static ALLIANCE = 10; // アライアンス
    static PAST_CANCEL = 11; // 過去解約案件
    static TELEPHONE_APPOINTMENT = 12; // テレアポ
    static OTHER = 19; // その他


    static values() {
        return {
            [this.CONTACT]: 'お問合せ',
            [this.DOWNLOAD]: 'ダウンロード',
            [this.PRESENT]: 'プレゼント',
            [this.ESTIMATE]: 'お見積り依頼',
            [this.DOCUMENT]: '資料請求',
            [this.FREE_CONSULT]: '無料相談',
            [this.SNS]: 'SNS',
            [this.INTRODUCTION]: '紹介',
            [this.COMPARE_SITE]: '比較サイト',
            [this.ALLIANCE]: 'アライアンス',
            [this.PAST_CANCEL]: '過去解約案件',
            [this.TELEPHONE_APPOINTMENT]: 'テレアポ',
            [this.OTHER]: 'その他',
        }
    }
}

export default ChannelCategory;
