/**
 * 確実度マスタ
 */
import { isBlank } from '@/utilities/typing'
import Department from '@/models/entities/department';

class Certainty {
    certainty_id;
    department;
    certainty_name;
    certainty_rate;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.certainty_id = properties.certainty_id;
        this.department = new Department(properties.department);
        this.certainty_name = properties.certainty_name;
        this.certainty_rate = properties.certainty_rate;
    }
}

export default Certainty;
