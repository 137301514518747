<template>
    <header class="page-title">
        <h1><i class="bi bi-people"></i> 案件一括変更</h1>
        <router-link :to="{name: 'Project'}" class="btn btn-outline-primary"><i class="bi bi-reply"></i> 案件一覧へ</router-link>
    </header>

    <inline-loader v-if="loading"></inline-loader>
    <template v-else>
        <section class="section">
            <div class="bg-primary mb-3 p-3 text-white">
                対象: {{ projects_count }}件
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    事業部
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.department_id"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-select
                        v-model="bulk_items.department_id"
                        :options="options_department"
                        :disabled="Boolean(Number(is_not_change.department_id))"
                        @selected="changeOptions()"
                    ></form-select>
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    追客担当
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.follow_user_id"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-select-search
                        v-model="bulk_items.follow_user_id"
                        :options="options_user"
                        :disabled="Boolean(Number(is_not_change.follow_user_id))"
                        empty_option="----"
                    ></form-select-search>
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    営業担当
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.sales_user_id"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-select-search
                        v-model="bulk_items.sales_user_id"
                        :options="options_user"
                        :disabled="Boolean(Number(is_not_change.sales_user_id))"
                        empty_option="----"
                    ></form-select-search>
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    ステータス
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.status"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-select
                        v-model="bulk_items.status"
                        :options="Status.options()"
                        :disabled="Boolean(Number(is_not_change.status))"
                    ></form-select>
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    優先度
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.probability_id"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-select
                        v-model="bulk_items.probability_id"
                        :options="options_probability"
                        :disabled="isDisabled(is_not_change.probability_id)"
                        empty_option="----"
                    ></form-select>
                </div>
                <div class="col-8 text-secondary">事業部の選択が必要です</div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    評価
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.rank"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-select
                        v-model="bulk_items.rank"
                        :options="Rank.options()"
                        :disabled="Boolean(Number(is_not_change.rank))"
                        empty_option="----"
                    ></form-select>
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    サブ評価
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.detailed_rank_id"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-select
                        v-model="bulk_items.detailed_rank_id"
                        :options="options_detailed_rank"
                        :disabled="isDisabled(is_not_change.detailed_rank_id)"
                        empty_option="----"
                    ></form-select>
                </div>
                <div class="col-8 text-secondary">事業部の選択が必要です</div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    確実度
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.certainty_id"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-select
                        v-model="bulk_items.certainty_id"
                        :options="options_certainty"
                        :disabled="isDisabled(is_not_change.certainty_id)"
                        empty_option="----"
                    ></form-select>
                </div>
                <div class="col-8 text-secondary">事業部の選択が必要です</div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    次回アプローチ日
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.next_approaching_date"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-input-date
                        v-model="bulk_items.next_approaching_date"
                        :disabled="Boolean(Number(is_not_change.next_approaching_date))"
                    ></form-input-date>
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    見込日
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.expected_order_date"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-input-date
                        v-model="bulk_items.expected_order_date"
                        :disabled="Boolean(Number(is_not_change.expected_order_date))"
                    ></form-input-date>
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    反響日
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.contact_date"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-input-date
                        v-model="bulk_items.contact_date"
                        :disabled="Boolean(Number(is_not_change.contact_date))"
                    ></form-input-date>
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    チャネル
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.channel_id"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-select-search
                        v-model="bulk_items.channel_id"
                        :options="options_channel"
                        :disabled="Boolean(Number(is_not_change.channel_id))"
                        empty_option="----"
                    ></form-select-search>
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-5 offset-1">
                    メルマガ購読
                </div>
                <div class="col-4">
                    <form-checkbox
                        v-model="is_not_change.subscribe_mail_magazine"
                        option_label="変更しない"
                    ></form-checkbox>
                </div>
                <div class="col-6">
                    <form-select
                        v-model="bulk_items.subscribe_mail_magazine"
                        :options="SubscribeMailMagazine.options()"
                        :disabled="Boolean(Number(is_not_change.subscribe_mail_magazine))"
                        empty_option="----"
                    ></form-select>
                </div>
            </div>
        </section>
        <section class="section">
            <button @click="bulkUpdate()" type="button" class="btn btn-lg btn-info">一括変更</button>
        </section>
    </template>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormSelect from '@/components/forms/FormSelect';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import FormInputDate from '@/components/forms/FormInputDate';
import FormCheckbox from '@/components/forms/FormCheckbox';
import Status from '@/models/enums/status';
import Rank from '@/models/enums/rank';
import SubscribeMailMagazine from '@/models/enums/subscribe-mail-magazine';
import Department from '@/models/entities/department';
import User from '@/models/entities/user';
import Channel from '@/models/entities/channel';
import Probability from '@/models/entities/probability';
import Certainty from '@/models/entities/certainty';
import DetailedRank from '@/models/entities/detailed-rank';

export default {
    name: 'ProjectBulk',
    components: {
        InlineLoader,
        FormSelect,
        FormSelectSearch,
        FormInputDate,
        FormCheckbox,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading: false,
            is_disabled: false,

            // 対象案件
            select_or_all: null,
            projects_count: 0,
            project_ids: [],
            condition: null,

            // enums
            Status,
            Rank,
            SubscribeMailMagazine,

            // options
            options_department: [],
            options_user: [],
            options_probability: [],
            options_detailed_rank: [],
            options_certainty: [],
            options_channel: [],

            is_not_change: {
                department_id: 1,
                follow_user_id: 1,
                sales_user_id: 1,
                status: 1,
                probability_id: 1,
                rank: 1,
                detailed_rank_id: 1,
                certainty_id: 1,
                next_approaching_date: 1,
                expected_order_date: 1,
                contact_date: 1,
                channel_id: 1,
                subscribe_mail_magazine: 1,
            },

            bulk_items: {
                department_id: null,
                follow_user_id: null,
                sales_user_id: null,
                status: null,
                probability_id: null,
                rank: null,
                detailed_rank_id: null,
                certainty_id: null,
                next_approaching_date: null,
                expected_order_date: null,
                contact_date: null,
                channel_id: null,
                subscribe_mail_magazine: null,
            },
        }
    },
    mounted() {
        // パラメータ取得
        this.select_or_all = this.$route.query.select_or_all;
        this.project_ids = this.$route.query.project_ids;
        this.condition = this.$store.getters['projectList/condition'];

        if (!this.$helper.isBlank(this.project_ids)) {
            this.projects_count = this.project_ids.length;
        } else if (!this.$helper.isBlank(this.condition) && !this.$helper.isBlank(this.condition)) {
            this.projects_count = this.$route.query.count;
        } else {
            this.showErrorMessage('一括更新対象が選択されていません。選択し直してください。');
            this.$router.push({name: 'Project'})
            return;
        }

        this.init();
    },
    methods: {
        async init() {
            this.loading = true;

            await this.fetchDepartments();

            // 事業部初期値セット
            this.bulk_items.department_id = this.options_department[0].value;
            // ステータス初期値セット
            this.bulk_items.status = this.Status.options()[0].value;

            await Promise.all([
                // ユーザ
                this.fetchUsers(),
                // チャネル
                this.fetchChannels(),
                // 優先度
                this.fetchProbabilities(), // 事業部id必要
                // サブ評価
                this.fetchDetailedRanks(), // 事業部id必要
                // 確実度
                this.fetchCertainties() // 事業部id必要
            ]);

            this.loading = false;
        },
        // 事業マスタ取得
        fetchDepartments() {
            return new Promise(resolve => {
                this.options_department.splice(0);

                this.$http.get('/master/department')
                .then(response => {
                    for (let row of response.data) {
                        let department = new Department(row);
                        this.options_department.push({value: department.department_id, label: department.department_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // ユーザマスタ取得
        fetchUsers() {
            return new Promise(resolve => {
                this.options_user.splice(0);

                this.$http.get('/user')
                .then(response => {
                    for (let row of response.data) {
                        let user = new User(row);
                        this.options_user.push({value: user.user_id, label: user.user_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // チャネルマスタ取得
        fetchChannels() {
            return new Promise(resolve => {
                this.options_channel.splice(0);

                this.$http.get('/master/channel')
                .then(response => {
                    for (let row of response.data) {
                        let channel = new Channel(row);
                        this.options_channel.push({value: channel.channel_id, label: channel.channel_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                });
            });
        },
        // 優先度マスタ取得
        fetchProbabilities() {
            return new Promise(resolve => {
                this.options_probability.splice(0);

                this.$http.get('/master/probability', {
                    params: {
                        department: this.bulk_items.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let probability = new Probability(row);
                        this.options_probability.push({value: probability.probability_id, label: probability.probability_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // サブ評価マスタ取得
        fetchDetailedRanks() {
            return new Promise(resolve => {
                this.options_detailed_rank.splice(0);

                this.$http.get('/master/detailed-rank', {
                    params: {
                        department: this.bulk_items.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let detailed_rank = new DetailedRank(row);
                        this.options_detailed_rank.push({value: detailed_rank.detailed_rank_id, label: detailed_rank.detailed_rank_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 確実度マスタ取得
        fetchCertainties() {
            return new Promise(resolve => {
                this.options_certainty.splice(0);

                this.$http.get('/master/certainty', {
                    params: {
                        department: this.bulk_items.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let certainty = new Certainty(row);
                        this.options_certainty.push({value: certainty.certainty_id, label: certainty.certainty_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 事業部が変更されたら再度取得
        async changeOptions() {
            if (this.bulk_items.department_id === null) {
                this.showErrorMessage('事業部を選択してください');
                return;
            }

            this.is_disabled = true;

            // リセット
            this.bulk_items.probability_id = null;
            this.bulk_items.detailed_rank_id = null;
            this.bulk_items.certainty_id = null;

            await Promise.all([
                // 優先度
                this.fetchProbabilities(),
                // サブ評価
                this.fetchDetailedRanks(),
                // 確実度
                this.fetchCertainties(),
            ]);

            this.is_disabled = false;
        },
        isDisabled(flag_id) {
            if (Number(flag_id)) {
                return true;
            }

            if (this.is_disabled) {
                return true;
            }

            return false;
        },
        // 一括更新
        bulkUpdate() {
            this.startScreenLoading();

            this.$http.post('/project/bulk', {
                'is_not_change': this.is_not_change,
                'bulk_items': this.bulk_items,
                'select_or_all': this.select_or_all,
                'project_ids': this.project_ids,
                'condition': this.condition
            })
            .then(() => {
                this.showMessage('一括更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<style scoped>

</style>
