/**
 * チャネルマスタ
 */
import { isBlank } from '@/utilities/typing'
import ChannelCategory from '@/models/enums/channel-category'
import Temperature from '@/models/enums/temperature'

class Channel {
    channel_id;
    channel_name;
    temperature;
    channel_category;
    is_excluded_from_stats;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.channel_id = properties.channel_id;
        this.channel_name = properties.channel_name;
        this.temperature = properties.temperature ?? Temperature.HOT;
        this.channel_category = properties.channel_category ?? ChannelCategory.CONTACT;
        this.is_excluded_from_stats = properties.is_excluded_from_stats ?? 0;
    }

    // 反響分類表示用
    get channel_category_label() {
        return ChannelCategory.get(this.channel_category);
    }

    // HOT/COLD表示用
    get temperature_label() {
        return Temperature.get(this.temperature);
    }
}

export default Channel;
