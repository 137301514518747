import Enum from './enum'

/**
 * HOT/COLD
 */
class Temperature extends Enum {
    static HOT = 1;
    static COLD = 2;

    static values() {
        return {
            [this.HOT]: 'HOT',
            [this.COLD]: 'COLD',
        }
    }
}

export default Temperature;
