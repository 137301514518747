<template>
    <Multiselect
        v-model="value"
        @select="select()"
        @deselect="select()"
        @clear="clear()"
        mode="single"
        :placeholder="placeholder"
        :options="selectOptions"
        :searchable="searchable"
        :required="required"
        :disabled="disabled"
        noResultsText="項目なし"
        class="form-select-search multiselect-blue"
        ref="multiselect"
    />
</template>

<script>
import Multiselect from '@vueform/multiselect';

// npm
// https://www.npmjs.com/package/@vueform/multiselect#events
export default {
    name: 'FormSelectSearch',
    components: {
        Multiselect,
    },
    data() {
        return {
            value: null,
            selectOptions: [],
        }
    },
    props: {
        modelValue: {
        },
        //SELECTで選択した結果を返す型
        model_type: {
            type: String,
            default: 'int' // int, float, string
        },
        options: {
            type: Array
        },
        option_value: {
            type: String,
            default: 'value'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: null,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'update:modelValue',
        'selected',
    ],
    mounted() {
        this.value = this.modelValue ?? null;

        if (this.option_label !== 'label' || this.option_value !== 'value') {
            this.selectOptions = this.formatOptions();
        } else {
            this.selectOptions = this.options;
        }
    },
    watch: {
        modelValue: function () {
            this.value = this.modelValue ?? null;
        },
        options: {
            handler: function () {
                this.selectOptions = this.formatOptions();
                if (this.value !== null) {
                    this.$refs.multiselect.select(this.value);
                }
            },
            deep: true,
        }
    },
    methods: {
        formatOptions() {
            if (!this.options) {
                return [];
            }

            return this.options.map((option) => {
                return {
                    value: option[this.option_value],
                    label: option[this.option_label]
                };
            });
        },
        convert() {
            if (this.value === null || this.value === '') {
                return null;
            } else if (this.model_type === 'int' || this.model_type === 'integer') {
                return parseInt(this.value);
            } else if (this.model_type === 'float') {
                return parseFloat(this.value);
            } else {
                return String(this.value);
            }
        },
        select() {
            this.$emit('update:modelValue', this.convert());
            this.$emit('selected', this.convert());
        },
        clear() {
            this.$emit('update:modelValue', null);
            this.$emit('selected', null);
        },
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css" />
<style>
.form-select-search .multiselect-multiple-label,
.form-select-search .multiselect-placeholder,
.form-select-search .multiselect-single-label {
    line-height: 1 !important;
}
</style>
<style scoped>
.multiselect-blue {
    --ms-line-height: 0.74;
    --ms-font-size: 0.9rem;
    --ms-option-bg-selected: #33AACC;
    --ms-option-bg-selected-pointed: #33AACC;
    --ms-py: 0.47rem;
    --ms-option-font-size: 0.9rem;
}
</style>