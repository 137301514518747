/**
 * 優先度
 */
import { isBlank } from '@/utilities/typing'

class Probability {
    probability_id;
    department_id;
    probability_name;
    priority;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.probability_id = properties.probability_id;
        this.department_id = properties.department_id;
        this.probability_name = properties.probability_name;
        this.priority = properties.priority;
    }
}

export default Probability;
