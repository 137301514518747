import Enum from './enum'

/**
 * ステータス
 */
class Status extends Enum {
    //static APPOINTMENT_LIST = 1;
    static NOT_CONTACT = 2;
    static PROCESSING = 20;
    //static DONE = 3;
    static LOST_CONTACT = 3;
    //static NOT_PICK_UP = 4;
    //static MAIL = 5;
    static DOCUMENT = 6;
    static SEMINAR = 7;
    static ARRANGE_APPOINTMENT = 8;
    static APPOINTMENT = 9;
    static RESCHEDULING = 10;
    static NEXT_DAY = 11;
    static FOLLOW = 12;
    //static REVISIT = 13;
    static MISSING = 13;
    static NOT_FOLLOW = 14;
    static OTHER_COMPANY = 15;
    static SELF_OPERATION = 16;
    static PASS = 17;
    static STARTED = 18;
    static SHOT = 19;

    static values() {
        return {
            //[this.APPOINTMENT_LIST]: 'アポリスト',
            [this.NOT_CONTACT]: '反響未対応',
            [this.PROCESSING]: '一次対応',
            //[this.DONE]: '対応済み',
            [this.LOST_CONTACT]: '音信不通',
            //[this.NOT_PICK_UP]: '電話にでんわ',
            //[this.MAIL]: 'メール送付',
            [this.DOCUMENT]: '資料送付',
            [this.SEMINAR]: 'セミナー',
            [this.ARRANGE_APPOINTMENT]: 'アポ調整中',
            [this.APPOINTMENT]: 'アポ',
            [this.RESCHEDULING]: 'リスケ',
            [this.NEXT_DAY]: '翌日',
            [this.FOLLOW]: '追客',
            //[this.REVISIT]: '再訪問',
            [this.MISSING]: '営業後応答なし',
            [this.NOT_FOLLOW]: '追客なし',
            [this.OTHER_COMPANY]: '他社落ち',
            [this.SELF_OPERATION]: '自社運用',
            [this.PASS]: '取得見送り',
            [this.SHOT]: 'ショット受注',
            [this.STARTED]: '受注',
        }
    }

    // override
    static options() {
        // キー順にソートされてしまうので、上書き
        return [
            {value: this.NOT_CONTACT, label: '反響未対応'},
            {value: this.PROCESSING, label: '一次対応'},
            {value: this.LOST_CONTACT, label: '音信不通'},
            {value: this.DOCUMENT, label: '資料送付'},
            {value: this.SEMINAR, label: 'セミナー'},
            {value: this.ARRANGE_APPOINTMENT, label: 'アポ調整中'},
            {value: this.APPOINTMENT, label: 'アポ'},
            {value: this.RESCHEDULING, label: 'リスケ'},
            {value: this.NEXT_DAY, label: '翌日'},
            {value: this.FOLLOW, label: '追客'},
            {value: this.MISSING, label: '営業後応答なし'},
            {value: this.NOT_FOLLOW, label: '追客なし'},
            {value: this.OTHER_COMPANY, label: '他社落ち'},
            {value: this.SELF_OPERATION, label: '自社運用'},
            {value: this.PASS, label: '取得見送り'},
            {value: this.SHOT, label: 'ショット受注'},
            {value: this.STARTED, label: '受注'},
        ];
    }

    // ステータスによって表示項目切り替えグループ
    static stageAction() {
        return [
            //this.APPOINTMENT_LIST,
            this.NOT_CONTACT,
            this.PROCESSING,
            //this.DONE,
            this.LOST_CONTACT,
            //this.NOT_PICK_UP,
            //this.MAIL,
            this.DOCUMENT,
            this.SEMINAR,
        ];
    }

    static stageSchedule() {
        return [
            this.ARRANGE_APPOINTMENT,
            this.APPOINTMENT,
            this.RESCHEDULING,
        ];
    }

    static stageNext() {
        return [
            this.NEXT_DAY,
            this.FOLLOW,
            //this.REVISIT,
            this.MISSING,
            this.SHOT,
        ];
    }

    static stageFailure() {
        return [
            this.SELF_OPERATION,
            this.PASS,
        ];
    }
}

export default Status;
