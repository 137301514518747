/**
 * ユーザマスタ
 */
import { isBlank } from '@/utilities/typing'
import Department from '@/models/entities/department';
import Division from '@/models/enums/division';
import Role from '@/models/enums/role';

class User {
    user_id;
    division;
    department;
    user_name;
    google_user_id;
    google_email;
    is_available;
    role;
    condition_id;
    user_image_path;
    chatwork_account_id;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.user_id = properties.user_id;
        this.division = properties.division;
        this.department = new Department(properties.department);
        this.user_name = properties.user_name;
        this.google_user_id = properties.google_user_id;
        this.google_email = properties.google_email;
        this.is_available = properties.is_available;
        this.role = properties.role;
        this.condition_id = properties.condition_id;
        this.user_image_path = properties.user_image_path;
        this.chatwork_account_id = properties.chatwork_account_id;
    }

    // 部門名
    get division_label() {
        return Division.get(this.division);
    }

    // 権限名
    get role_label() {
        return Role.get(this.role);
    }
}

export default User;
