import Enum from './enum'

/**
 * メールマガジン購読
 */
class SubscribeMailMagazine extends Enum {
    static YES = 1; // 希望する
    static NO = 0; // 希望しない

    static values() {
        return {
            [this.YES]: '希望する',
            [this.NO]: '希望しない',
        }
    }
}

export default SubscribeMailMagazine;
