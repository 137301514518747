/**
 * サブ評価マスタ
 */
import { isBlank } from '@/utilities/typing'
import Department from '@/models/entities/department';

class DetailedRank {
    detailed_rank_id;
    department;
    detailed_rank_name;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.detailed_rank_id = properties.detailed_rank_id;
        this.department = new Department(properties.department);
        this.detailed_rank_name = properties.detailed_rank_name;
    }
}

export default DetailedRank;
